<template>
  <div>
    <v-row>
      <v-col cols="12" style="text-align:center">
        <h1>Time Tracker</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" />
      <v-col cols="2" style="text-align:center">
        <p>
          If you already have an account, you need
          to log in!
        </p>
        <v-btn @click="$auth.loginWithRedirect()">Login</v-btn>
      </v-col>
      <v-col cols="1" />
      <v-col cols="2" style="text-align:center">
        <p>
          If you do not have an account, contact us
          so that we can create one for you!
        </p>
        <v-btn href="mailto:info@geniustalentgroup.com">Contact Us</v-btn>
      </v-col>
      <v-col cols="2" />
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Start"
}
</script>

<style scoped>

</style>
